.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.rounded-md {
  border-radius: 0.375rem;
}
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 13px;
}
.no-select {
  user-select: none;
}
.app-header {
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
}
.table-hover tbody tr {
  cursor: pointer;
  user-select: none;
}

tbody tr {
  td .visible-on-row-hover {
    visibility: hidden;
  }
  &:hover {
    td .visible-on-row-hover {
      visibility: visible;
    }
  }
}

.dev-wrapper {
  font-size: 0.8rem;
  a {
    display: block;
    padding: 10px;
    text-align: center;
    color: #787878;
    &:hover {
      color: #787878;
      text-decoration: underline;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  // outline: 1px solid slategrey;
  outline: none;
  border-radius: 4px;
}

@primary-color: #0047ba;@body-background: rgba(249, 250, 251, 1);@layout-body-background: transparent;@font-family: Rubik, sans-serif;@select-item-selected-font-weight: 700;@typography-title-font-weight: 700;@layout-header-background: #0047ba;@layout-header-padding: 0 30px;@layout-sider-background: #fff;@table-selected-row-bg: #fafafa;@menu-bg: #fff;@menu-item-active-bg: #f3f3f3;@menu-highlight-color: #333;@menu-item-active-border-width: 0;@menu-collapsed-width: 60px;@menu-dark-item-hover-bg: rgba(0,0,0,0.2);@layout-trigger-height: 48px;@layout-trigger-background: #f2f2f2;@layout-trigger-color: #333;